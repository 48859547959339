.navbar-link:hover {
  text-decoration: underline;
  text-underline-offset: 5px;
}

.navbar-link {
  font-family: "GiloryBold";
  font-weight: bold;
  padding: 7px;
  margin-left: 5px;
  cursor: pointer;
  color: var(--primary-color) !important;
  font-size: larger !important;
  text-align: center;
}

.profile-nav {
  text-align: center;
  border-bottom: none !important;
  margin-left: 20px;
  margin-right: 20px;
}

.profile-nav > a::after {
  display: none;
}

.profile-nav > div {
  min-width: 100px;
  left: -200% !important;
  text-align: center;
  padding: 20px;
}

.profile-nav > div > a {
  font-size: 16px;
  /* margin-bottom: 20px; */
}

.profile-nav > div > hr {
  width: 90%;
  margin: 20px auto 20px auto;
}
