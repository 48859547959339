.info-section,
.work-section {
  min-height: 100vh;
}

.info-item {
  position: relative;
  word-break: break-all;
}
.info-item,
.work-item {
  height: 100vh;
  min-height: fit-content;
  color: var(--primary-color) !important;
}

.previous-work-cards {
  margin: 20px;
  margin-top: 100px;
}

.info-item > h3 {
  margin: 20px 0px 20px 0px;
}

.info-item > h4 {
  font-size: 14px;
}

.work-description {
  font-family: "GiloryLight";
  font-weight: lighter;
}

.vendor-work-carousel {
  position: relative;
}

.vendor-work-carousel > div > {
  position: relative;
}

.vendor-work-carousel,
.vendor-work-img {
  height: 95vh;
}

.vendor-work-caption {
  position: absolute;
  width: fit-content;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  color: white;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 10px;
  margin-bottom: 25px;
}

.user-rating {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
}

.post-reviews {
  margin-top: 20px;
  color: var(--primary-color) !important;
  text-align: center;
}

.post-reviews > hr {
  margin-bottom: 20px;
}
.info-item-img {
  margin: auto;
  width: 100%;
}
.info-item-img > img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.user-chat-btn {
  background-color: transparent;
  border: none;
  color: var(--primary-color) !important;
  font-size: 18px;
  width: fit-content;
  margin: 10px auto 10px auto;
  color: white !important;
  position: relative;
}
.user-broadcast-btn {
  background-color: transparent;
  border: none;
  position: relative;
}
.send-broadcast-btn {
  /* background-image: url("../../../assets/images/btnbg.jpeg");
  background-size: cover;
  background-repeat: no-repeat; */
  color: white !important;
  font-size: 18px !important;
}

.user-report-btn {
  min-width: 220px;

  background-color: transparent;
  border: none;
  color: red;
  float: right;
  margin-top: -50px;
}

@media only screen and (max-width: 1000px) {
  .user-report-btn {
    margin-top: -80px;
  }
}

.user-chat {
  width: fit-content;
  margin-left: auto;
  text-align: right;
  word-break: break-word;
  text-align: unset;
  max-width: 50%;
}

.chat-box {
  max-height: 50vh;
  overflow: auto;
  border: 1px solid var(--primary-color) !important;
  margin-bottom: 10px;
  overflow-x: hidden;
  padding: 10px;
}

/* width */
.chat-box::-webkit-scrollbar {
  width: 2px;
}

/* Track */
.chat-box::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.chat-box::-webkit-scrollbar-thumb {
  background: var(--primary-color) !important;
  border-radius: 10px;
}

/* Handle on hover */
.chat-box::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color) !important;
}

.send-report-btn {
  font-size: 18px !important;
  /* background-color: red !important; */
  color: white !important;
  /* background-image: url("../../../assets/images/btnbg.jpeg");
  background-size: cover;
  background-repeat: no-repeat; */
}
