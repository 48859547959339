html {
  overflow-x: hidden !important;
}

body {
  margin: 0;
  font-family: "Gilory";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
}

code {
  font-family: "Gilory";
}

h1,
h2,
h3,
h4,
h5,
p {
  color: var(--primary-color) !important;
}

@font-face {
  font-family: "Gilory";
  src: local("Gilory"), url(./fonts/Gilroy-Black.woff) format("woff");
}

@font-face {
  font-family: "GiloryBold";
  src: local("GiloryBold"), url(./fonts/Gilroy-Bold.woff) format("woff");
}

@font-face {
  font-family: "GiloryMedium";
  src: local("GiloryMedium"), url(./fonts/Gilroy-Medium.woff) format("woff");
}
@font-face {
  font-family: "GiloryLight";
  src: local("GiloryLight"), url(./fonts/Gilroy-Light.woff) format("woff");
}

@font-face {
  font-family: "Rexagon";
  src: local("Rexagon"), url(./fonts/rexagon.ttf) format("ttf");
}

button {
  font-family: "Gilory" !important;
}

textarea {
  border-color: var(--primary-color) !important;
}

p {
  font-size: 14px;
}

.MuiPopover-paper {
  box-shadow: none !important;
}
